import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import ability from "@/libs/acl/ability";
import useFilterCounter from "@/components/Filters/useFiltersCount";
import { debounce } from "lodash"

export default function useList() {
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch('tenant/getAll')
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  const tableColumns = [
    { label: "luna.master_account.name", key: "name", sortable: true },
    { label: "luna.master_account.description", key: "description", sortable: true },
    { label: 'luna.master_account.api_email', key: 'api_email', sortable: true },
    { label: 'luna.master_account.api_password', key: 'api_password', sortable: true },
    { label: 'luna.master_account.api_server', key: 'api_server', sortable: true },
    { label: 'tenants.title', key: 'tenants', sortable: false, formatter: (value, key, item) => {
      if(item.tenants) return [...new Set(item.tenants.map(item => { return item.name}))].join(', ')
      return ''
      }
    },
    { label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned' },
    { label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned' },
  ];
  if (
    ability.can("update", "LunaMasterAccount") == true ||
    ability.can("delete", "LunaMasterAccount") == true
  ) {
    tableColumns.splice(0, 0, { label: "actions.name", key: "actions" });
  }

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("luna_master_account/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        tenant: tenantFilter.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([
    currentPage,
    perPage,
  ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => { refetchData() }, 500))

  // filters counter ============================
  const filters = ref({});
  const filtersCount = useFilterCounter(filters);
  const buildFilters = () => {
    filters.value = {
      tenant: tenantFilter.value,
    };
    refetchData();
  }
  const clearSearchFilters = () => {
    tenantFilter.value = null;
    buildFilters();
  }
  // ============================================

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    tenantOptions,
    tenantFilter,
    refetchData,

    filtersCount,
    buildFilters,
    clearSearchFilters,
  };
}
